.landing {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .begin {
    border-radius: 100px;
    min-width: 300px;
    width: 50%;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.8s linear;

    &.start {
      opacity: 0;
    }

    em {
      font-size: 3rem;
      font-style: normal;
      margin-right: 1rem;
      position: relative;
      top: -0.3rem;
    }
  }
}