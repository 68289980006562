.feeling {
  font-size: 0.8rem;

  .explain {
    margin-top: 1rem;
  }

  .feels {
    margin-top: 1rem;
    display: grid;
    aspect-ratio: 3/2;
    grid-template-rows: 30% 30% 30%;
    grid-template-columns: 30% 30% 30%;
    gap: 5%;

    .feeling {
      background-color: var(--feeling-background);
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      transition: background-color 0.15s linear;

      strong, small {
        transition: font-size 0.15s linear;
      }

      &:hover {
        background-color: var(--feeling-hover-background);
      }

      &.active {
        background-color: var(--primary);

        strong {
          font-size: 2.5rem;
        }

        small {
          font-size: 0;
        }
      }

      small {
        font-size: 0.65rem;
      }
    }
  }
}