.photo {
  img {
    cursor: pointer;
  }

  .credits {
    display: block;
    text-align: right;
    font-size: 0.7rem;
    opacity: 0.4;
    transition: opacity 0.3s linear;

    &:hover {
      opacity: 1;
    }

    a {
      margin-left: 0.1rem;
    }
  }

  .photo-choice {
    display: flex;
    font-size: 0.7rem;
    margin-bottom: 0.5rem;

    label {
      margin-right: 1rem;
    }
  }

  .toolbar {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 0.5rem;

    a.gallery {
      padding: 0;
      margin-right: 1rem;
      font-size: 0.7rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.25rem;
      }
    }

    small {
      margin-left: auto;
      flex-shrink: 0;
    }
  }
}

.yarl__container {
  background: var(--main-background) !important;
}

.yarl__button {
  width: auto;
  color: var(--primary) !important;
  background-color: var(--card-background-color) !important;
  opacity: 0.3;
  transition: opacity 0.3s linear;

  &:hover {
    opacity: 1;
  }

  &[aria-label="Download"] {
    margin-right: 0.5rem;
  }
}