.music {
  .player-container {
    min-width: 300px;
  }

  .music-picker {
    summary:not([role]) {
      max-height: 2.5rem;
      padding-top: 0.7rem;

      &::after {
        margin-top: -0.2rem;
      }

      > div {
        font-size: 0.75rem;
        width: 85%;
        max-width: 85%;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      small {
        font-size: 0.6rem;
        margin-left: 0.5rem;
      }
    }

    ul {
      overflow: hidden;

      li:hover {
        cursor: pointer;
        color: var(--primary);
      }
    }

    ul small {
      display: block;
      font-size: 0.6rem;
    }
  }

  iframe {
    aspect-ratio: 356/140;
    width: 100%;
    background: transparent;
  }

  label {
    margin-top: 0.5rem;
    text-align: right;
    font-size: 0.7rem;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s linear;

    &:hover {
      opacity: 1;
    }
  }

  button {
    font-size: 0.8rem;
  }
}