@import "mixin";

:root, [data-theme=dark] {
  --layout-padding: 3%;
  --sidebar-width: 440px;
  @include dark-theme;
}

[data-theme=light] {
  @include light-theme;
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    @include dark-theme;
  }
}

@media only screen and (prefers-color-scheme: light) {
  :root:not([data-theme]) {
    @include light-theme;
  }
}