.theme {
  text-align: right;
  font-size: 0.65rem;

  label {
    cursor: pointer;
  }

  &:hover {
    opacity: 1;
  }
}