@import "mixin";

.relax {
  max-width: 1400px;
  display: flex;
  margin-left: auto;
  margin-right: auto;

  > .appear-after {
    flex-grow: 1;
    max-width: calc(100% - var(--sidebar-width));
  }

  .sidebar {
    min-width: var(--sidebar-width);
    width: var(--sidebar-width);
    position: sticky;
    top: 0;
    margin-bottom: auto;
    padding: var(--layout-padding);
    max-height: 100vh;
    overflow: auto;

    @include scrollbars;
  }

  main {
    flex-grow: 1;
    background: var(--wiki-background);
    padding: var(--layout-padding);
    width: 100%;
    overflow-x: hidden;

    .mwe-math-element {
      background-color: #fff;
      padding: 2px 5px;
      border-radius: 2px;
      display: inline-block;
    }

    * {
      width: fit-content;
      max-width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .relax {
    flex-direction: column;

    > .appear-after {
      max-width: inherit;
    }

    .sidebar {
      position: static;
      width: 100%;
      max-width: 100%;
      min-width: auto;
      padding-top: var(--layout-padding);
      max-height: inherit;
    }

    main {
      width: 100%;
      max-width: 100%;
      padding: var(--layout-padding);
      background: transparent;
    }
  }
}