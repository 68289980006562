@import "mixin";

html {
  min-height: 100%;
  height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

#root, .layout {
  min-height: 100%;
  height: 100%;
}

.layout {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: var(--main-background);
  background-size: cover;
  background-attachment: fixed;

  .main {
    flex-grow: 1;
  }

  @include scrollbars;
}

aside li a {
  display: inline;
}

.box {
  background: var(--box-background);
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  img {
    border-radius: 0.25rem;
  }

  &:first-child {
    margin-top: 0;
  }
}

.appear-after {
  opacity: 0;
  transition: opacity 1.75s linear;

  &.now-visible {
    opacity: 1;
  }
}

.share-modal {
  input {
    width: 600px;
    max-width: 100%;
  }
}

dialog article > header .close {
  text-indent: 100%;
  overflow: hidden;
}