@use "~@picocss/pico/scss/_variables";

footer {
  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: 0.1rem;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    font-size: 0.8rem;
    opacity: 0.5;
    transition: opacity 0.5s linear;

    &:hover {
      opacity: 1;
    }

    li {
      list-style: none;
    }
  }

  .locale-picker {
    margin-bottom: 0;
    font-size: 0.7rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
}


@media (min-width: 768px) {
  footer ul {
    gap: 2rem;
    flex-direction: row;
  }
}