.kofi {
  border-radius: 20px;
  font-size: 0.7rem;
  padding: 0.25rem 1.5rem 0.25rem 2.5rem;
  position: relative;

  &::before {
    background-image: url("../../public/other/kofi.png");
    position: absolute;
    width: 32px;
    height: 32px;
    content: "";
    left: 0.5rem;
    top: -2px;
  }
}