.wikipedia {
  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: var(--typography-spacing-vertical);

    h1 {
      margin-bottom: 0;
    }

    button {
      font-size: 0.7rem;
      margin-left: 0.5rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
  }

  .toolbar {
    display: flex;
    gap: 0.5rem;
  }
}

@media (max-width: 768px) {
  .wikipedia header {
    flex-direction: column;

    button {
      margin-left: 0;
    }

    h1 {
      margin-bottom: calc(var(--typography-spacing-vertical) / 2);
    }
  }
}