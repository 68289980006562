@mixin dark-theme {
  --main-background: linear-gradient(#021526, #0F2F59);
  --box-background: #031125;
  --wiki-background: #132e51;
  --scroll-background: #021526;
  --scroll-thumb: #224b81;
  --feeling-background: #152943;
  --feeling-hover-background: #1a3b65;
}

@mixin light-theme {
  --main-background: white;
  --box-background: #eef0f3;
  --wiki-background: #eef0f3;
  --scroll-background: #ddd;
  --scroll-thumb: #bbb;
  --feeling-background: #ffffff;
  --feeling-hover-background: #e3e3e3;
}

@mixin scrollbars {
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--scroll-background);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scroll-thumb);
  }
}

@mixin center-page($selector) {
  #{$selector} {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15vh;
  }

  @media (max-width: 768px) {
    #{$selector} {
      max-width: 80%;
    }
  }
}